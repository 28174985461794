import {Project} from './project.model';
import {HateoasLink} from './hateosLink.model';

export class Workspace {
    links: HateoasLink[] | undefined = [];
    projects: Project[] | undefined = [];
    workspaceName: string | undefined;
    workspaceImage: string | undefined;
    workspaceId: string | undefined;
}
