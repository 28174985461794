<ng-container *ngIf="auth.isAuthenticated$ && auth.user$ | async as user; else loggedOut">
    <button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu" mat-icon-button>
        <img [src]="user.picture" alt="Profile picture" class="profile-dropdown-button"/>
    </button>
    <mat-menu #menu="matMenu">
        <button mat-menu-item routerLink="settings">
            <mat-icon>settings</mat-icon>
            <span>Settings</span>
        </button>
        <button (click)="auth.logout()" mat-menu-item>
            <mat-icon>logout</mat-icon>
            <span>Log Out</span>
        </button>
    </mat-menu>
</ng-container>

<ng-template #loggedOut>
    <button (click)="auth.loginWithRedirect()" mat-raised-button>Log in</button>
</ng-template>
