import {Project} from '../../../core/models/project.model';

export class ProjectAndIdeationId {
    project: Project | undefined;
    ideationId: string | undefined;

    constructor(project: Project | undefined, ideationId: string | undefined) {
        this.project = project;
        this.ideationId = ideationId;
    }
}
