import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '@auth0/auth0-angular';
import {JoinComponent} from './features/teams/components/join/join.component';
import {ReferralGuard} from './core/guard/referral.guard';

const routes: Routes = [
    {
        path: 'ideas',
        loadChildren: () => import('./features/ideas/idea.module').then(m => m.IdeaModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'settings',
        loadChildren: () => import('./features/settings/settings.module').then(m => m.SettingsModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'workspaces',
        loadChildren: () => import('./features/workspace/workspace.module').then(m => m.WorkspaceModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'onboarding',
        loadChildren: () => import('./features/onboarding/onboarding.module').then(m => m.OnboardingModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'teams',
        loadChildren: () => import('./features/teams/teams.module').then(m => m.TeamsModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'join/:companyId/:referralId',
        component: JoinComponent,
        canActivate: [ReferralGuard],
    },
    // Any path that doesn't match returns the user home
    {
        path: '',
        redirectTo: 'workspaces',
        pathMatch: 'full'
    },
    {
        path: '**',
        redirectTo: 'workspaces'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
