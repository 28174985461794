import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {HateoasCollection} from '../models/hateoasCollection.model';
import {Observable} from 'rxjs';
import {Project} from '../models/project.model';

@Injectable({
    providedIn: 'root'
})
export class ProjectService {
    projectApiUrl = `${environment.server_api}` + 'project';
    workspaceApiUrl = `${environment.server_api}` + 'workspace';

    projectAndAssessmentEndpoint = `${environment.server_api}` + 'project-and-assessment';

    constructor(private http: HttpClient) {
    }

    getProjectsInWorkspace(workspaceId: string | undefined): Observable<HateoasCollection> {
        return this.http.get<HateoasCollection>(this.projectApiUrl + '?workspaceId=' + workspaceId);
    }

    createProject(workspaceId: string | undefined, project: Project): Observable<Project> {
        return this.http.post<Project>(this.workspaceApiUrl + '/' + workspaceId + '/project', project);
    }

    updateProject(project: Project): Observable<Project> {
        return this.http.put<Project>(this.projectApiUrl + '/' + project.projectId, project);
    }

    deleteProject(project: Project): Observable<void> {
        return this.http.delete<void>(this.projectApiUrl + '/' + project.projectId);
    }

    getScoreboardData(workspaceId: string): Observable<HateoasCollection> {
        return this.http.get<HateoasCollection>(this.projectAndAssessmentEndpoint + '?workspaceId=' + workspaceId);
    }
}
