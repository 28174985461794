export const environment = {
    production: true,
    server_api: 'https://api.valuable.ringfence.nz/',
    auth_callback_uri: 'https://app.valuable.ringfence.nz/auth/callback',
    SENTRY_DSN: 'https://2b24a72cacbe4b128d66bf1f8e6a3929@sentry.io/1886701',
    SENTRY_ENVIRONMENT: 'prod',
    SENTRY_RELEASE: '98'
};

export const authConfig = {
    'clientId': 'aI7oNtnN8S7s6oT4ELLYc2QrM9KCwb7Z',
    'domain': 'ringfence.au.auth0.com',
    'audience': 'https://api.valuable.ringfence.nz'
};
