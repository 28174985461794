import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {concatMap, Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {Team} from '../models/team.model';
import {UserService} from './user.service';
import {HateoasCollection} from '../models/hateoasCollection.model';
import {take} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class TeamService {
    teamApiUrl = `${environment.server_api}` + 'teams';

    constructor(private http: HttpClient, private userService: UserService) {
    }

    getTeam(id: string): Observable<Team> {
        return this.http.get<Team>(this.teamApiUrl + '/' + id);
    }

    getAllTeamsForLoggedInUserCompany(): Observable<HateoasCollection> {
        return this.userService.getUserParentCompany()
            .pipe(take(1))
            .pipe(
                concatMap(company => {
                    return this.http.get<HateoasCollection>(this.teamApiUrl + '?companyId=' + company.companyId);
                }));
    }

    updateTeam(team: Team) {
        return this.http.put<Team>(this.teamApiUrl + '/' + team.teamId, team);
    }

    createTeam(team: Team) {
        return this.http.post<Team>(this.teamApiUrl, team);
    }
}
