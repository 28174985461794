import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

import * as Sentry from '@sentry/angular-ivy';
import {Integrations} from '@sentry/tracing';

Sentry.init({
    dsn: environment.SENTRY_DSN,
    environment: environment.SENTRY_ENVIRONMENT,
    release: environment.SENTRY_RELEASE,
    integrations: [
        new Integrations.BrowserTracing({
            tracePropagationTargets: ['localhost', 'https://api.valuable.ringfence.nz'],
            routingInstrumentation: Sentry.routingInstrumentation,
        }),
        new Sentry.Replay()
    ],
    tracesSampleRate: 0.5,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
