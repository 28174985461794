import {Injectable} from '@angular/core';
import {Company} from '../models/company.model';
import {HttpClient} from '@angular/common/http';
import {ValuableUserProfile} from '../models/valuableUserProfile.model';
import {concatMap, Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {HateoasCollection} from '../models/hateoasCollection.model';
import {take} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    userApiUrl = `${environment.server_api}` + 'users';

    constructor(private http: HttpClient) {
    }

    getLoggedInUser(): Observable<ValuableUserProfile> {
        return this.http.get<ValuableUserProfile>(this.userApiUrl + '/me');
    }

    getUserByAuth0Id(auth0Id: string): Observable<ValuableUserProfile> {
        return this.http.get<ValuableUserProfile>(this.userApiUrl + '/findByAuth0Id' + '?auth0Id=' + auth0Id);
    }

    updateUser(user: ValuableUserProfile): Observable<ValuableUserProfile> {
        return this.http.put<ValuableUserProfile>(this.userApiUrl + '/me', user);
    }

    createUser(user: ValuableUserProfile): Observable<ValuableUserProfile> {
        return this.http.post<ValuableUserProfile>(this.userApiUrl, user);
    }

    getUserParentCompany(): Observable<Company> {
        return this.http.get<Company>(this.userApiUrl + '/me/company');
    }

    getAllUsersForTeam(teamId: string): Observable<HateoasCollection> {
        return this.http.get<HateoasCollection>(this.userApiUrl + '?teamId=' + teamId);
    }

    getAllUsersForCompany(): Observable<HateoasCollection> {
        return this.getUserParentCompany()
            .pipe(take(1))
            .pipe(
                concatMap(company => {
                    return this.http.get<HateoasCollection>(this.userApiUrl + '?companyId=' + company.companyId);
                }));
    }
}
