import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import {Observable, of} from 'rxjs';
import {AuthService} from '@auth0/auth0-angular';
import {map, take, switchMap} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ReferralGuard  {
    constructor(private authService: AuthService) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.authService.isAuthenticated$
            .pipe(
                take(1),
                switchMap(val => {
                    if (val) {
                        return of(true);
                    }
                    return this.authService.loginWithRedirect({
                        authorizationParams: {
                            screen_hint: 'signup'
                        },
                        appState: {
                            target: state.url
                        },
                    }).pipe(map((_) => {
                        return false;
                    }));
                })
            );
    }
}
