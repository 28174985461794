import {ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {Router} from '@angular/router';

import * as Sentry from '@sentry/angular-ivy';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AuthHttpInterceptor, AuthModule} from '@auth0/auth0-angular';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NavigationComponent} from './core/components/navigation/navigation.component';
import {LayoutModule} from '@angular/cdk/layout';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyListModule as MatListModule} from '@angular/material/legacy-list';
import {AuthenticationComponent} from './core/components/authentication/authentication.component';
import {SharedModule} from './shared/shared.module';

import {authConfig, environment} from '../environments/environment';
import {MatExpansionModule} from '@angular/material/expansion';
import {ScoreboardComponent} from './shared/scoreboard/scoreboard.component';

@NgModule({
    declarations: [
        AppComponent,
        NavigationComponent,
        AuthenticationComponent,
        ScoreboardComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        AuthModule.forRoot({
            domain: authConfig.domain,
            clientId: authConfig.clientId,
            httpInterceptor: {
                allowedList: [
                    {
                        // Match any request that matches the uri
                        uri: `${environment.server_api}*`
                    }
                ]
            },
            authorizationParams: {
                audience: authConfig.audience,
                redirect_uri: window.location.origin
            }
        }),
        BrowserAnimationsModule,
        LayoutModule,
        MatToolbarModule,
        MatButtonModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        SharedModule,
        HttpClientModule,
        MatExpansionModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthHttpInterceptor,
            multi: true
        },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: true,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {

    constructor(trace: Sentry.TraceService) {
    }
}
