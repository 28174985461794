import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, concatMap, Observable, tap} from 'rxjs';
import {UserService} from './user.service';
import {take} from 'rxjs/operators';
import {HateoasCollection} from '../models/hateoasCollection.model';
import {Workspace} from '../models/workspace.model';
import {Project} from '../models/project.model';
import {ProjectService} from './project.service';
import {TagService} from './tag.service';
import {ProjectAndIdeationId} from '../../features/workspace/model/project-and-ideation.id';

@Injectable({
    providedIn: 'root'
})
export class WorkspaceService {
    workspaceApiUrl = `${environment.server_api}` + 'workspace';
    private routedWorkspace = new BehaviorSubject(new Workspace());
    routedWorkspace$ = this.routedWorkspace.asObservable();

    constructor(private http: HttpClient,
                public userService: UserService,
                public projectService: ProjectService,
                public tagService: TagService) {
    }

    nextWorkspace(workspace: Workspace) {
        this.routedWorkspace.next(workspace);
    }

    getWorkspacesForLoggedInUserCompany(): Observable<HateoasCollection> {
        return this.userService.getUserParentCompany()
            .pipe(take(1))
            .pipe(
                concatMap(company => {
                    return this.http.get<HateoasCollection>(this.workspaceApiUrl + '?companyId=' + company.companyId);
                }));
    }

    saveWorkspace(newWorkspace: Workspace): Observable<Workspace> {
        return this.http.post<Workspace>(this.workspaceApiUrl, newWorkspace);
    }

    getWorkspace(workspaceId: string): Observable<Workspace> {
        return this.http.get<Workspace>(this.workspaceApiUrl + '/' + workspaceId)
            .pipe(
                tap(value => {
                    this.nextWorkspace(value);
                })
            );
    }

    updateWorkspace(workspace: Workspace): Observable<Workspace> {
        return this.http.put<Workspace>(this.workspaceApiUrl + '/' + workspace.workspaceId, workspace);
    }

    getWorkspacesAndProjectsForCompany(workspaceId: string) {
        let tmpWorkspace: Workspace = new Workspace();
        const tmpWorkspaceProjects: Array<ProjectAndIdeationId> = [];
        this.getWorkspace(workspaceId)
            .pipe(take(1))
            .subscribe({
                next: workspace => {
                    tmpWorkspace = workspace;
                    this.nextWorkspace(workspace);
                    this.projectService.getProjectsInWorkspace(workspace.workspaceId)
                        .pipe(take(1))
                        .subscribe({
                            next: collection => {
                                collection.content.forEach(project => {
                                    this.tagService.getTagsForProject(project.projectId)
                                        .pipe(take(1))
                                        .subscribe({
                                            next: tagCollection => {
                                                const ideationId = this.extractedId(project);
                                                const projectWithTags = project;
                                                projectWithTags.tags = tagCollection.content;
                                                const newProjectAndIdeationId = new ProjectAndIdeationId(projectWithTags, ideationId);
                                                tmpWorkspaceProjects.push(newProjectAndIdeationId);
                                            }
                                        });
                                });
                                return {
                                    workspace: tmpWorkspace,
                                    workspaceProjects: tmpWorkspaceProjects
                                };
                            },
                            error: err => {
                                if (err.error.code === 404 && err.error.message.includes('does not contain any projects')) {
                                    return err;
                                }
                            }
                        });
                }
            });
        return {
            workspace: tmpWorkspace,
            workspaceProjects: tmpWorkspaceProjects
        };
    }

    extractedId(project: Project): string | undefined {
        const ideationHref = project.links.find((link: { rel: string; }) => link.rel === 'ideation')?.href;
        const lastSlash = ideationHref?.lastIndexOf('/') ?? 0;
        return ideationHref?.substring(lastSlash + 1);
    }
}
