import {NgModule} from '@angular/core';
import {UnderscorePipePipe} from './pipes/underscore-pipe.pipe';
import {BooleanToStringPipe} from './pipes/boolean-to-string.pipe';
import {InlineLoadingComponent} from './inline-loading/inline-loading.component';
import {LoadingComponent} from './loading/loading.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {RouterModule} from '@angular/router';
import {MatLegacySlideToggleModule as MatSlideToggleModule} from '@angular/material/legacy-slide-toggle';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatSortModule} from '@angular/material/sort';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {MatLegacyTableModule as MatTableModule} from '@angular/material/legacy-table';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {MatLegacyTabsModule as MatTabsModule} from '@angular/material/legacy-tabs';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyListModule as MatListModule} from '@angular/material/legacy-list';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatStepperModule} from '@angular/material/stepper';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {MatLegacyRadioModule as MatRadioModule} from '@angular/material/legacy-radio';
import {MatLegacySliderModule as MatSliderModule} from '@angular/material/legacy-slider';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatLegacyMenuModule as MatMenuModule} from '@angular/material/legacy-menu';
import {ValuableWorkflowStepsListComponent} from './valuable-workflow-steps-list/valuable-workflow-steps-list.component';
import {ValuableWorkflowStepsItemComponent} from './valuable-workflow-steps-item/valuable-workflow-steps-item.component';
import {CommonModule} from '@angular/common';
import {ProjectCardComponent} from './project-card/project-card.component';
import {EditProjectDialogComponent} from './edit-project-dialog/edit-project-dialog.component';
import {NewProjectDialogComponent} from './new-project-dialog/new-project-dialog.component';
import {IdeationCardComponent} from './ideation-card/ideation-card.component';
import {MatLegacyChipsModule as MatChipsModule} from '@angular/material/legacy-chips';
import {MatLegacyAutocompleteModule as MatAutocompleteModule} from '@angular/material/legacy-autocomplete';
import {ScoreboardChartComponent} from './scoreboard-chart/scoreboard-chart.component';

@NgModule({
    declarations: [
        LoadingComponent,
        InlineLoadingComponent,
        UnderscorePipePipe,
        BooleanToStringPipe,
        ValuableWorkflowStepsListComponent,
        ValuableWorkflowStepsItemComponent,
        ProjectCardComponent,
        EditProjectDialogComponent,
        NewProjectDialogComponent,
        IdeationCardComponent,
        ScoreboardChartComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatToolbarModule,
        MatSidenavModule,
        MatRadioModule,
        ReactiveFormsModule,
        MatCheckboxModule,
        MatIconModule,
        RouterModule,
        MatCardModule,
        MatDialogModule,
        MatMenuModule,
        MatListModule,
        MatFormFieldModule,
        MatSelectModule,
        MatTableModule,
        MatButtonModule,
        MatInputModule,
        MatSortModule,
        MatSliderModule,
        MatTooltipModule,
        MatProgressSpinnerModule,
        MatTabsModule,
        MatStepperModule,
        MatDividerModule,
        MatFormFieldModule,
        MatSlideToggleModule,
        MatGridListModule,
        MatChipsModule,
        MatAutocompleteModule
    ],
    providers: [],
    exports: [
        LoadingComponent,
        InlineLoadingComponent,
        UnderscorePipePipe,
        MatToolbarModule,
        MatSidenavModule,
        MatRadioModule,
        ReactiveFormsModule,
        MatCheckboxModule,
        MatIconModule,
        RouterModule,
        MatCardModule,
        MatDialogModule,
        MatListModule,
        MatFormFieldModule,
        MatSelectModule,
        MatTableModule,
        MatButtonModule,
        MatInputModule,
        MatSortModule,
        MatMenuModule,
        MatSliderModule,
        MatTooltipModule,
        MatProgressSpinnerModule,
        BooleanToStringPipe,
        MatTabsModule,
        MatStepperModule,
        MatDividerModule,
        MatFormFieldModule,
        MatSlideToggleModule,
        MatGridListModule,
        ValuableWorkflowStepsListComponent,
        ProjectCardComponent,
        ScoreboardChartComponent,
    ],
    bootstrap: []
})
export class SharedModule {
}
