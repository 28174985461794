<app-loading *ngIf="isLoading"></app-loading>

<div *ngIf="!isLoading">
    <mat-card class="data-card">
        <mat-card-title *ngIf="!hideTeamField">Join {{this.teamBeingJoined?.name}}
            at {{this.companyBeingJoined?.name}}</mat-card-title>
        <mat-card-title *ngIf="hideTeamField">Join {{this.companyBeingJoined?.name}}</mat-card-title>
        <mat-card-content>
            <p>Please Check that all of your details have been entered correctly, then click join.</p>
            <form [formGroup]="referralConfirmationFormGroup"
                  class="user-invite-form">
                <mat-form-field appearance="fill" class="user-invite-form-field">
                    <mat-label>First Name</mat-label>
                    <input formControlName="userReferralFirstNameFormCtrl" matInput>
                </mat-form-field>
                <br>
                <mat-form-field appearance="fill" class="user-invite-form-field">
                    <mat-label>Last Name</mat-label>
                    <input formControlName="userReferralLastNameFormCtrl" matInput>
                </mat-form-field>
                <br>
                <mat-form-field appearance="fill" class="user-invite-form-field">
                    <mat-label>Email Address</mat-label>
                    <input formControlName="userReferralEmailFormCtrl" matInput>
                </mat-form-field>
            </form>

            <button (click)="joinCompany()" class="base-button" color="primary" mat-raised-button>
                Join {{this.companyBeingJoined?.name}}</button>

            <mat-list role="list">
                <mat-list-item *ngIf="hasJoined" role="listitem">
                    <mat-label>Successfully Joined:</mat-label>
                    <p class="yes-or-no">Yes</p>
                    <mat-icon>check</mat-icon>
                </mat-list-item>
                <mat-list-item *ngIf="!hasJoined" role="listitem">
                    <mat-label>Successfully Joined:</mat-label>
                    <p class="yes-or-no">No</p>
                    <mat-icon>warning</mat-icon>
                </mat-list-item>
            </mat-list>
        </mat-card-content>
    </mat-card>
</div>