<mat-toolbar color="primary">
    <mat-toolbar-row class="primary-toolbar">
        <button class="valuable-title" mat-button routerLink="">Valuable</button>
        <button class="valuable-nav-item primary-toolbar-text" mat-button routerLink="workspaces">Workspaces</button>
        <mat-divider *ngIf="activeWorkspace?.workspaceId !== undefined"
                     class="workspace-vertical-divider"
                     vertical></mat-divider>
        <button *ngIf="activeWorkspace?.workspaceId !== undefined"
                class="valuable-nav-item primary-toolbar-text"
                mat-button
                routerLink="workspaces/{{activeWorkspace?.workspaceId}}">
            {{activeWorkspace?.workspaceName}}
        </button>
        <button class="valuable-nav-item primary-toolbar-text" mat-button routerLink="teams">Team</button>
        <span class="spacer"></span>
        <app-authentication></app-authentication>
    </mat-toolbar-row>
</mat-toolbar>
