import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Company} from '../../../../core/models/company.model';
import {ReferralCodeStatus} from '../../../../core/models/referralCodeStatus.model';
import {ValuableUserProfile} from '../../../../core/models/valuableUserProfile.model';
import {ActivatedRoute, Router} from '@angular/router';
import {CompanyService} from '../../../../core/services/company.service';
import {concatMap, of} from 'rxjs';
import {TeamService} from '../../../../core/services/team.service';
import {AuthService, User} from '@auth0/auth0-angular';
import {Team} from '../../../../core/models/team.model';

@Component({
    selector: 'app-join',
    templateUrl: './join.component.html',
    styleUrls: ['./join.component.scss', '../../teams.component.scss']
})
export class JoinComponent implements OnInit {
    referralTitle = 'Join your Company';
    companyId: string | undefined;
    referralId: string | undefined;
    referralItem: ReferralCodeStatus | undefined;
    isLoading = true;
    companyBeingJoined: Company | undefined;
    referralConfirmationFormGroup: UntypedFormGroup;
    teamBeingJoined: Team | undefined;
    hideTeamField = false;
    hasJoined = false;
    valuableUserToBeAdded: ValuableUserProfile | undefined;
    auth0ProfileOfUserToBeAdded: User | null | undefined;

    constructor(private route: ActivatedRoute,
                private companyService: CompanyService,
                public teamService: TeamService,
                public authService: AuthService,
                private router: Router,
                private _formBuilder: UntypedFormBuilder) {
        this.referralConfirmationFormGroup = this._formBuilder.group({
            userReferralFirstNameFormCtrl: ['', Validators.required],
            userReferralLastNameFormCtrl: ['', Validators.required],
            userReferralEmailFormCtrl: ['', Validators.required]
        });
    }

    ngOnInit() {

        this.route.params.pipe(
            concatMap(() => {
                return this.authService.user$;
            }),
            concatMap((loggedInUser) => {
                this.auth0ProfileOfUserToBeAdded = loggedInUser;
                this.companyId = this.route.snapshot.params.companyId;
                this.referralId = this.route.snapshot.params.referralId;
                return this.companyService.getReferralLinkForCompany(this.companyId!, this.referralId!);
            }),
            concatMap((returnedReferral: ReferralCodeStatus) => {
                this.referralItem = returnedReferral;
                this.referralConfirmationFormGroup.get('userReferralFirstNameFormCtrl')?.setValue(this.referralItem.firstName);
                this.referralConfirmationFormGroup.get('userReferralLastNameFormCtrl')?.setValue(this.referralItem.lastName);
                this.referralConfirmationFormGroup.get('userReferralEmailFormCtrl')?.setValue(this.referralItem.emailAddress);
                return this.companyService.linkUserAndCompanyForReferral(this.referralItem);
            }),
            concatMap((linkResponse: any) => {
                return this.companyService.getCompany(this.referralItem?.entryCompanyId!);
            }),
            concatMap((companyResponse: Company) => {
                this.companyBeingJoined = companyResponse;
                if (this.referralItem?.entryTeamId !== null) {
                    return this.teamService.getTeam(this.referralItem?.entryTeamId!);
                } else {
                    return of(new Team());
                }
            })
        ).subscribe({
            next: returnedTeam => {
                this.teamBeingJoined = returnedTeam;
                this.referralTitle = `Join ${this.companyBeingJoined?.name}`;
                if (this.teamBeingJoined.name === '') {
                    this.hideTeamField = true;
                }
                this.isLoading = false;
            },
            error: err => {
            }
        });
    }

    joinCompany() {
        const status = new ReferralCodeStatus();
        status.firstName = this.referralConfirmationFormGroup.get('userReferralFirstNameFormCtrl')?.value;
        status.lastName = this.referralConfirmationFormGroup.get('userReferralLastNameFormCtrl')?.value;
        status.emailAddress = this.referralConfirmationFormGroup.get('userReferralEmailFormCtrl')?.value;
        status.referralCodeStatusId = this.referralItem?.referralCodeStatusId;
        status.entryTeamId = this.referralItem?.entryTeamId;
        status.entryCompanyId = this.referralItem?.entryCompanyId;
        status.used = this.referralItem?.used;

        this.companyService.completeReferralProcessForCompany(this.companyId!, status).subscribe(returnedUser => {
            this.valuableUserToBeAdded = returnedUser;
            this.hasJoined = true;
            this.router.navigate(['/workspaces']);
        });
    }
}
