import {HateoasLink} from './hateosLink.model';

export class Team {
    teamId: string | undefined;
    name: string | undefined;
    primaryContactEmailAddress: string | undefined;
    links: HateoasLink[] | undefined;


    constructor() {
    }
}
