export class ReferralCodeStatus {
    referralCodeStatusId: string | undefined;
    referralCodeUrl: string | undefined;
    status: string | undefined;
    used: string | undefined;
    entryTeamId: string | undefined;
    entryCompanyId: string | undefined;
    firstName: string | undefined;
    lastName: string | undefined;
    emailAddress: string | undefined;
}
