import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {HateoasCollection} from '../models/hateoasCollection.model';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TagService {
  tagApiUrl = `${environment.server_api}tags`;

  constructor(private http: HttpClient) {
  }

  getTagsForProject(projectId: string): Observable<HateoasCollection> {
    return this.http.get<HateoasCollection>(this.tagApiUrl + '?projectId=' + projectId);
  }

  getAllTags(): Observable<HateoasCollection> {
    return this.http.get<HateoasCollection>(this.tagApiUrl);
  }
}
