import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Company} from '../models/company.model';
import {ReferralCodeStatus} from '../models/referralCodeStatus.model';
import {ValuableUserProfile} from '../models/valuableUserProfile.model';
import {ReferralPacket} from '../models/referralPacket.model';

@Injectable({
    providedIn: 'root'
})
export class CompanyService {
    companyApiUrl = `${environment.server_api}` + 'companies';

    constructor(private http: HttpClient) {
    }

    getAllCompanies(): Observable<Company[]> {
        return this.http.get<Company[]>(this.companyApiUrl);
    }

    getCompany(id: string): Observable<Company> {
        return this.http.get<Company>(this.companyApiUrl + '/' + id);
    }

    createCompany(company: Company) {
        return this.http.post<Company>(this.companyApiUrl, company);
    }

    updateCompany(company: Company) {
        return this.http.put<Company>(this.companyApiUrl + '/' + company.companyId, company);
    }

    createReferralLinkForCompany(referral: ReferralPacket): Observable<ReferralCodeStatus> {
        return this.http.put<ReferralCodeStatus>(
            this.companyApiUrl + '/' + referral.entryCompanyId + '/join?referral=' + referral.referralCodeId, referral);
    }

    getReferralLinkForCompany(companyId: string, referralId: string): Observable<ReferralCodeStatus> {
        return this.http.get<ReferralCodeStatus>(
            this.companyApiUrl + '/' + companyId + '/join?referral=' + referralId);
    }

    completeReferralProcessForCompany(companyId: string, referral: ReferralCodeStatus): Observable<ValuableUserProfile> {
        return this.http.put<ValuableUserProfile>(
            this.companyApiUrl + '/' + companyId + '/complete?referral=' + referral.referralCodeStatusId, referral);
    }

    linkUserAndCompanyForReferral(referral: ReferralCodeStatus | undefined) {
        return this.http.patch(
            this.companyApiUrl + '/' + referral?.entryCompanyId + '/join?referral=' + referral?.referralCodeStatusId, null);
    }
}
